<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="detail">
      <b-card>
        <div class="pb-3">
          <b-button
            variant="primary"
            class="btn-icon mr-1"
            @click="() => $router.go(-1)"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button variant="success" class="btn-icon mr-1">
            <feather-icon icon="RefreshCwIcon" size="16" />
          </b-button>
        </div>
        <b-row>
          <b-col cols="6" class="d-flex justify-content-between">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-avatar
                  class="mb-1 mr-2"
                  size="6rem"
                  :src="detail.sender.avatar_url"
                  :initials="detail.sender.initial_letters"
                ></b-avatar>
                <div>
                  <h4 class="mb-0">
                    {{ detail.sender.full_name }}
                  </h4>
                  <span class="card-text"
                    ><strong>Email:</strong> {{ detail.sender.email }}</span
                  >
                  <br />
                  <span class="card-text">
                    <strong>Telefono:</strong> {{ detail.sender.phone }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Registrado desde:</strong>
                    {{ dateFormat(detail.sender.created_at) }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Tipo de cuenta:</strong>
                    {{ detail.sender.account_type }}
                  </span>
                  <br />
                </div>
              </div>
              <div class="text-right">
                <!--verified element-->
                <div v-if="detail.sender.verified">
                  <b-badge variant="success" class="ml-1">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-25">Verificado</span>
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="ml-1">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                    <span class="align-middle ml-25">No verificado</span>
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="6" class="d-flex justify-content-between">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-avatar
                  class="mb-1 mr-2"
                  size="6rem"
                  :src="detail.receiver.avatar_url"
                  :initials="detail.receiver.initial_letters"
                ></b-avatar>
                <div>
                  <h4 class="mb-0">
                    {{ detail.receiver.full_name }}
                  </h4>
                  <span class="card-text"
                    ><strong>Email:</strong> {{ detail.receiver.email }}</span
                  >
                  <br />
                  <span class="card-text">
                    <strong>Telefono:</strong> {{ detail.receiver.phone }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Registrado desde:</strong>
                    {{ dateFormat(detail.receiver.created_at) }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Tipo de cuenta:</strong>
                    {{ detail.receiver.account_type }}
                  </span>
                  <br />
                </div>
              </div>
              <div class="text-right">
                <!--verified element-->
                <div v-if="detail.receiver.verified">
                  <b-badge variant="success" class="ml-1">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-25">Verificado</span>
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="ml-1">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                    <span class="align-middle ml-25">No verificado</span>
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="6" class="pt-5">
            <h2 class="mb-2">Detalles de la transacción</h2>
            <h4>Monto: {{ detail.amount }}</h4>
            <h4>Fecha: {{ dateFormat(detail.created_at) }}</h4>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { inputAlert, confirmAlert } from '@/helpers/utils'

moment.locale('es')
import {
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BCardText,
  VBTooltip,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

import numberMixins from '@/mixins/numberMixins'

export default {
  components: {
    DatePicker,
    BBadge,
    vSelect,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BCardText,
    BTab,
    BTabs,
  },
  mixins: [numberMixins],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState({
      loading: (state) => state.sendMoney.loading,
      detail: (state) => state.sendMoney.detail,
    }),
  },
  data() {
    return {
      showDocument: false,
      urlDocument: '',
      verifyFile: null,
    }
  },
  watch: {},
  methods: {
    async documentVerify(id, status, type) {
      const res = await this.$store.dispatch('sendMoney/documentVerify', {
        id,
        status: status,
        type,
      })
    },
    getNameFileType(type) {
      switch (type) {
        case 'front':
          return 'Frente'
        case 'backDocument':
          return 'Trasera'
        case 'selfie':
          return 'Selfie'
        default:
          return 'No definido'
      }
    },
    showFile(doc) {
      this.urlDocument = doc.url
      this.showDocument = true
      this.verifyFile = doc
    },
    getDetail() {
      this.$store.dispatch('sendMoney/getDetail', {
        id: this.$route.params.id,
      })
    },
    async approve(status) {
      let res = {
        isConfirmed: false,
        value: '',
      }
      if (status !== 'completed') {
        res = await inputAlert(
          'Rechazar recarga',
          'Ingrese el montivo del rechazo'
        )
      } else {
        res = await confirmAlert(
          'Aprobar recarga',
          '¿ Esta seguro de aprobar esta recarga ?'
        )
      }

      if (!res.isConfirmed) return

      this.$store.dispatch('sendMoney/approve', {
        id: this.$route.params.id,
        status: status,
        motive_rejected: res.value,
      })
    },
  },
  created() {
    this.getDetail()
  },
}
</script>
